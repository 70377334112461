<template>
  <div>
    <v-card flat dense>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title
        ><h5 class="font-weight-light">LIST OF REQUEST FOR APPROVAL</h5>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row>
          <v-col md="3" cols="12">
            <v-select
              class="mx-2"
              v-model="type_of_request"
              dense
              label="Type of Request"
              :items="['Regular','PDC','FTV']"
              :rules="rules.combobox_rule"
              @change="selected_type"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="4" cols="12">
            <v-data-table
              :key="key"
              dense
              class="mt-2"
              :headers="headers"
              :items="list_of_request_pending"
              hide-default-footer
              disable-pagination
              height="450"
            >
              <template v-slot:item="{ item }">
                <tr
                  @click="activerow(item)"
                  :class="{ 'info white--text': item.id === selectedDepositId }"
                >
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.category.category }}
                  </td>
                  <td>
                    {{ compute_total(item.request_data) }}
                  </td>
                  <td v-if="item.is_approved === 2">
                    {{ '(Created By: '+item.created_by.last_name+', '+
                    item.created_by.first_name+') Approve By: '+item.approved_by.last_name+', '+
                    item.approved_by.first_name}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col md="8" cols="12">
            <div v-if="this.request_data.length>0">
              <v-icon
                v-if="!is_deleting_request "
                class="mr-2"
                color="warning"
                @click="change_status_pending_request(selected_request.id, 0,selected_request.approved_id)"
              >
                {{ icons.mdiThumbDown }}
              </v-icon>Disapprove
              <v-progress-circular
                color="info"
                indeterminate
                v-if="is_deleting_request"
              ></v-progress-circular>

              <v-icon
                v-if="!is_deleting_request"
                class="mr-2"
                color="primary"
                @click="change_status_pending_request(selected_request.id, 1,selected_request.approved_id)"
              >
                {{ icons.mdiThumbUp }}
              </v-icon>
              Approve
              <v-progress-circular
                color="info"
                indeterminate
                v-if="is_deleting_request"
              ></v-progress-circular>
              <v-icon
                v-if="!is_deleting_request"
                class="mr-2"
                color="error"
                @click="delete_pending_request(selected_request.id)"
              >
                {{ icons.mdiDelete }}
              </v-icon>
              Delete
              <v-progress-circular
                color="info"
                indeterminate
                v-if="is_deleting_request"
              ></v-progress-circular>
            </div>
            <RequestData
              :key="this.key"
              :request_data="this.request_data"
              :total_amount="this.total_amount"
              :selectedDepositId="this.selectedDepositId"
              :is_deleting_req="this.is_deleting_request"
              :can_up_del="true"
              :is_regular="this.is_regular"
              :is_pdc="this.is_pdc"
              :is_ftv="this.is_ftv"

              v-on:edit_request_data="edit_request_data"
              v-on:delete_data="delete_data"
            ></RequestData>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="EditInfoRequest" persistent max-width="50%">
      <request-information
        v-on:data="on_save_request_data"
        :key="this.key"
        :category_id="this.category_id"
        :request_id="this.request_id"
        :is_regular="this.is_regular"
        :is_pdc="this.is_pdc"
        :is_ftv="this.is_ftv"
        :branch_items="this.branch_items"
        :particulars_items="this.particulars_items"
        :from_bank_items="this.from_bank_items"
        :to_bank_items="this.to_bank_items"
        :is_from_other="1"
        :from_others="this.selected_request_data"
      ></request-information>
      <v-btn color="error" @click="EditInfoRequest = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiDelete,
    mdiThumbDown,
    mdiThumbUp,
    mdiPencil,
    mdiPrinter,
    mdiCashCheck,
  } from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";
  import RequestData from "@/views/file_interface/create/request/request_components/RequestData";
  import RequestInformation
    from "@/views/file_interface/create/request/request_components/RequestInformation";

  export default {
    components: {
      snackBarDialog,
      RequestInformation,
      RequestData,
    },
    setup() {
      return {
        EditInfoRequest: false,
        type_of_request: '',
        selectedDepositId: 0,
        selected_request_data: {},
        selected_request: {},

        is_regular: 0,
        is_pdc: 0,
        is_ftv: 0,
        request_data: [],
        total_amount: '0.00',

        from_bank_items: [],
        to_bank_items: [],
        particulars_items: [],
        branch_items: [],
        request_id: 0,
        category_id: 0,
        is_deleting: false,

        key: 0,
        is_deleting_request: false,
        icons: {
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiDelete,
          mdiThumbDown,
          mdiThumbUp,
          mdiPencil,
          mdiPrinter,
          mdiCashCheck,
        },
        list_of_request_pending: [],
        headers: [
          {text: 'ID', value: '', sortable: false},
          {text: 'Category', value: '', sortable: false},
          {text: 'Amount', value: '', sortable: false},
          {text: '', value: '', sortable: false},
        ],
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request', [
        'list_of_request_for_final_approve',
        'register_request_form',
        'delete_request_form',
        'change_status_request_form',
      ]),
      ...mapActions('request_data', ['delete_request_data', 'initialize_edit_request_data']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      activerow(value) {
        this.selected_request = value
        this.selectedDepositId = value.id
        this.total_amount = this.compute_total(value.request_data)
        this.request_data = value.request_data
      },
      compute_total(data) {
        var tto = 0
        data.forEach(function (item) {
          tto += parseFloat(item.amount)
        })
        return this.formatPrice(tto)
      },
      selected_type(value) {
        this.selectedDepositId = -1
        this.list_of_request_pending = []
        this.request_data = []
        const data = new FormData()
        this.is_regular = value === 'Regular' ? 1 : 0
        this.is_pdc = value === 'PDC' ? 1 : 0
        this.is_ftv = value === 'FTV' ? 1 : 0

        data.append('is_regular', this.is_regular)
        data.append('is_pdc', this.is_pdc)
        data.append('is_ftv', this.is_ftv)
        this.list_of_request_for_final_approve(data)
          .then(response => {
            this.key++
            this.list_of_request_pending = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      delete_data(id) {
        if (confirm('PROCEED?')) {
          this.is_deleting = true
          const data = new FormData()
          data.append('id', id)
          this.delete_request_data(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.is_deleting = false
              this.selected_month()
            })
            .catch(error => {
              console.log(error)
              this.is_deleting = false
            })
        }
      },
      async edit_request_data(value) {
        if (confirm('PROCEED?')) {
          await this.initialize_edit_request_data({
            id: value.request_id,
          })
            .then(response => {
              this.branch_items = response.data[0].branches
              this.particulars_items = response.data[0].particulars
              this.from_bank_items = response.data[0].banks
              this.to_bank_items = response.data[0].banks
            })
            .catch(error => {
              console.log(error)
            })
          this.key++
          this.EditInfoRequest = true
          this.request_id = value.request_id
          this.category_id = value.category_id
          this.selected_request_data = value
        }
      },
      async on_save_request_data(value) {
        this.key++
        this.EditInfoRequest = false
        await this.selected_type(this.type_of_request)
      },
      delete_pending_request(id) {
        if (confirm('PROCEED?')) {
          this.is_deleting_request = true
          this.alert = false
          const data = new FormData()
          data.append('request_id', id)
          this.delete_request_form(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.is_deleting_request = false
              this.selected_type(this.type_of_request)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.is_deleting_request = false
            })
        }
      },
      change_status_pending_request(id, value, employee_id) {
        if (confirm('PROCEED?')) {
          this.is_deleting_request = true
          this.alert = false
          const data = new FormData()
          data.append('request_id', id)
          data.append('approved_id', employee_id)
          data.append('status', value)
          this.change_status_request_form(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.is_deleting_request = false
              this.selected_type(this.type_of_request)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.is_deleting_request = false
            })
        }
      },
    }
  }
</script>

<style scoped>
</style>
